import i18n from 'i18next';
import { getFormattedValue } from 'shared/common/utils';
import { Reading } from 'shared/reading/types';

import { ReadingName } from 'domain/domain.models';
import { getDomainConfig } from 'domain/domain.utils';
import { MeterType, Unit } from 'models/index';

import { isTaidocMeterReading } from '../is-taidoc-meter-reading/is-taidoc-meter-reading';

enum MeterRangeType {
  EU_MARKET = 'EU_MARKET',
  US_MARKET = 'US_MARKET',
  TAIDOC = 'TAIDOC',
}

const { GLUCOSE_BLOOD, KETONE_BLOOD } = ReadingName;
const { MGDL, MMOLL } = Unit;
const { GKI_METER, VIRTA_METER, GK_PLUS_METER } = MeterType;
const { EU_MARKET, US_MARKET, TAIDOC } = MeterRangeType;

export const isMeterDerivedReading = (
  reading: Pick<
    Reading,
    'type' | 'sampleType' | 'unit' | 'meterType' | 'serialNumber'
  >
) => getRange(reading) !== null;

/**
 * Formats reading value based on its type, unit and meter/source.
 *
 * Should be used, whenever the value can be associated with some particular
 * reading and we've got info about its source/meter. For example, all the readings
 * from "/readings" endpoint, or LAST_READING from "/readings/stats"
 *
 * Similarly, it should NOT be used for average, min, max, etc. values from
 * "/readings/stats" endpoint, because they are not associated with any particular
 * reading and we don't have info about its source/meter.
 */
export const formatReadingValue = (
  reading: Pick<
    Reading,
    'type' | 'sampleType' | 'value' | 'unit' | 'meterType' | 'serialNumber'
  >
) => {
  const { value, unit, type, sampleType } = reading;
  const { readingName } = getDomainConfig(type, sampleType);

  const range = getRange(reading);
  if (range) {
    const [min, max] = range;

    if (value < min) {
      return i18n.t('LO');
    }

    if (value > max) {
      return i18n.t('HI');
    }
  }

  return getFormattedValue({ value, unit, readingName });
};

const getRange = (
  reading: Pick<
    Reading,
    'type' | 'sampleType' | 'unit' | 'meterType' | 'serialNumber'
  >
) => {
  const meterRangeType = getMeterRangeType(reading);
  const { type, sampleType, unit } = reading;
  const { readingName } = getDomainConfig(type, sampleType);

  if (meterRangeType === EU_MARKET) {
    if (readingName === GLUCOSE_BLOOD && unit === MGDL) {
      return [10, 600];
    }
    if (readingName === GLUCOSE_BLOOD && unit === MMOLL) {
      return [0.6, 33.3];
    }
    if (readingName === KETONE_BLOOD) {
      return [0.1, 8];
    }
  }

  if (meterRangeType === US_MARKET) {
    if (readingName === GLUCOSE_BLOOD && unit === MGDL) {
      return [20, 600];
    }
    if (readingName === GLUCOSE_BLOOD && unit === MMOLL) {
      return [1.1, 33.3];
    }
    if (readingName === KETONE_BLOOD) {
      return [0.1, 8];
    }
  }

  if (meterRangeType === TAIDOC) {
    if (readingName === GLUCOSE_BLOOD && unit === MGDL) {
      return [10, 700];
    }
    if (readingName === GLUCOSE_BLOOD && unit === MMOLL) {
      return [0.6, 38.9];
    }
    if (readingName === KETONE_BLOOD) {
      return [0.1, 8];
    }
  }

  return null;
};

const getMeterRangeType = (
  reading: Pick<Reading, 'meterType' | 'serialNumber'>
): MeterRangeType | null => {
  const { meterType } = reading;

  if (meterType === GKI_METER) {
    return EU_MARKET;
  }

  if (meterType === GK_PLUS_METER || meterType === VIRTA_METER) {
    return US_MARKET;
  }

  if (isTaidocMeterReading(reading)) {
    return TAIDOC;
  }

  return null;
};
