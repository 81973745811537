import { useHistory } from 'react-router-dom';
import { regionsMetadataStore } from 'shared/region-metadata/store';
import { useLoggedInUser } from 'shared/user/store';

import { getBottomMenuItems } from 'features/navbar/components/TopNav/utils/get-bottom-menu-items';

interface AppFooterNavigationLinksProps {
  linkClassName?: string;
}

export const AppFooterNavigationLinks = ({
  linkClassName,
}: AppFooterNavigationLinksProps) => {
  const user = useLoggedInUser();
  const history = useHistory();
  const regionMetadata = regionsMetadataStore.getCurrentRegionMetadata();

  const handleRouteChange = (value: string) => {
    const [linkType, link] = value.split(' ');

    if (linkType === 'internalLink') {
      history.push(link);
    }

    if (linkType === 'externalLink') {
      window.location.href = link;
    }
  };

  const options = getBottomMenuItems({
    userRoles: user.roles,
    oldDashboardUrl: regionMetadata.oldDashboardUrl,
  });

  return (
    <>
      {options.map((option) => (
        <li
          key={option.value}
          className={linkClassName}
          onClick={() => handleRouteChange(option.value)}
        >
          {option.label}
        </li>
      ))}
    </>
  );
};
