export enum ModalName {
  REMOVE_PATIENT = 'REMOVE_PATIENT',
  REMOVE_PATIENTS = 'REMOVE_PATIENTS',
  REINVITE_PATIENT = 'REINVITE_PATIENT',
  REINVITE_PATIENTS = 'REINVITE_PATIENTS',
  APPROVE_PATIENT = 'APPROVE_PATIENT',
  APPROVE_PATIENTS = 'APPROVE_PATIENTS',
  REJECT_PATIENT = 'REJECT_PATIENT',
  REJECT_PATIENTS = 'REJECT_PATIENTS',
  MOVE_PATIENT = 'MOVE_PATIENT',
  MOVE_PATIENTS = 'MOVE_PATIENTS',
  COPY_PATIENT = 'COPY_PATIENT',
  COPY_PATIENTS = 'COPY_PATIENTS',
  UPDATE_PATIENT_RANGES = 'UPDATE_PATIENT_RANGES',
  UPDATE_PATIENTS_RANGES = 'UPDATE_PATIENTS_RANGES',
  MESSAGE_PATIENT = 'MESSAGE_PATIENT',
  MESSAGE_PATIENTS = 'MESSAGE_PATIENTS',
  TAG_PATIENT = 'TAG_PATIENT',
  TAG_PATIENTS = 'TAG_PATIENTS',
  EXPORT_PATIENT_DATA = 'EXPORT_PATIENT_DATA',
  EXPORT_PATIENTS_DATA = 'EXPORT_PATIENTS_DATA',
  EXPORT_ALL_PATIENTS_DATA = 'EXPORT_ALL_PATIENTS_DATA',
  EXPORT_USER_DATA = 'EXPORT_USER_DATA',
  SHARE_PATIENT_DATA = 'SHARE_PATIENT_DATA',
  SHARE_OWN_DATA = 'SHARE_OWN_DATA',
  CONNECT_PATIENT_TO_EHR_MODAL = 'CONNECT_PATIENT_TO_EHR_MODAL',
  SEND_PATIENT_DATA_TO_EHR = 'SEND_PATIENT_DATA_TO_EHR',
  DISCONNECT_DEXCOM = 'DISCONNECT_DEXCOM',
  DISCONNECT_PARTNER = 'DISCONNECT_PARTNER',
  DELETE_AFFILIATE_LINK = 'DELETE_AFFILIATE_LINK',
  CONNECT_TO_KM_STORE = 'CONNECT_TO_KM_STORE',
  VERIFY_KM_STORE_EMAIL = 'VERIFY_KM_STORE_EMAIL',
  CHANGE_KM_STORE_EMAIL = 'CHANGE_KM_STORE_EMAIL',
  ACTIVATE_KM_STORE_ACCOUNT = 'ACTIVATE_KM_STORE_ACCOUNT',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_EMAIL = 'CHANGE_EMAIL',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  CREATE_PREDEFINED_READING_TAG = 'CREATE_PREDEFINED_READING_TAG',
  UPDATE_PREDEFINED_READING_TAG = 'UPDATE_PREDEFINED_READING_TAG',
  DELETE_PREDEFINED_READING_TAG = 'DELETE_PREDEFINED_READING_TAG',
  ADD_EVENT = 'ADD_EVENT',
  UPDATE_EVENT = 'UPDATE_EVENT',
  DELETE_EVENT = 'DELETE_EVENT',
  DELETE_EVENTS = 'DELETE_EVENTS',
  REMOVE_PRACTICE = 'REMOVE_PRACTICE',
  IMPORT_READINGS = 'IMPORT_READINGS',
  REVEAL_READINGS = 'REVEAL_READINGS',
  EDIT_READING = 'EDIT_READING',
  ADD_READING = 'ADD_READING',
  INVITE_USERS = 'INVITE_USERS',
  INVITE_HCPS = 'INVITE_HCPS',
  AUTHORIZED_ACCESS = 'AUTHORIZED_ACCESS',
  SHARE_DATE_IN_SHARED_PRACTICE = 'SHARE_DATE_IN_SHARED_PRACTICE',
  PAUSE_SHARING_DATA_IN_SHARED_PRACTICE = 'PAUSE_SHARING_DATA_IN_SHARED_PRACTICE',
  LEAVE_PRACTICE = 'LEAVE_PRACTICE',
  REMOVE_HCP = 'REMOVE_HCP',
  PROMOTE_HCP = 'PROMOTE_HCP',
  UNHIDE_READINGS = 'UNHIDE_READINGS',
  UNHIDE_READING = 'UNHIDE_READING',
  UNHIDE_ALL_READINGS = 'UNHIDE_ALL_READINGS',
  HIDE_OR_DELETE_READINGS = 'HIDE_OR_DELETE_READINGS',
  HIDE_READING = 'HIDE_READING',
  DELETE_READING = 'DELETE_READING',
  PROMOTE_HCP_TO_PRACTICE_ADMIN = 'PROMOTE_HCP_TO_ADMIN',
  DEMOTE_HCP_FROM_PRACTICE_ADMIN = 'DEMOTE_HCP_FROM_PRACTICE_ADMIN',
  TRANSFER_PRACTICE_OWNERSHIP = 'TRANSFER_PRACTICE_OWNERSHIP',
  REMOVE_HCP_FROM_PRACTICE = 'REMOVE_HCP_FROM_PRACTICE',
  LEAVE_PRACTICE_AS_HCP = 'LEAVE_PRACTICE_AS_HCP',
  REMOVE_HCP_REQUEST = 'REMOVE_HCP_REQUEST',
  APPROVE_HCP_REQUEST = 'APPROVE_HCP_REQUEST',
  REJECT_HCP_REQUEST = 'REJECT_HCP_REQUEST',
  REINVITE_HCP = 'REINVITE_HCP',
  ENABLE_DEBUG_MODE = 'ENABLE_DEBUG_MODE',
  RESET_PRACTICE_RANGE_DESCRIPTIONS = 'RESET_PRACTICE_RANGE_DESCRIPTIONS',
  REMOVE_RANGE_DESCRIPTION = 'REMOVE_RANGE_DESCRIPTION',
  UPDATE_PRACTICE_DATA_SHARING = 'UPDATE_PRACTICE_DATA_SHARING',
}

/*
    Used when we need to have multiple modals of the same type on the same page.
*/
export type ExtendedModalName = `${ModalName}_${string}`;

export type ModalId = ModalName | ExtendedModalName;
